import request from '@/utils/request'


// 查询申请模板列表
export function listTemplate(query) {
  return request({
    url: '/apply/template/list',
    method: 'get',
    params: query
  })
}

// 查询申请模板分页
export function pageTemplate(query) {
  return request({
    url: '/apply/template/page',
    method: 'get',
    params: query
  })
}

// 查询申请模板详细
export function getTemplate(data) {
  return request({
    url: '/apply/template/detail',
    method: 'get',
    params: data
  })
}

// 新增申请模板
export function addTemplate(data) {
  return request({
    url: '/apply/template/add',
    method: 'post',
    data: data
  })
}

// 修改申请模板
export function updateTemplate(data) {
  return request({
    url: '/apply/template/edit',
    method: 'post',
    data: data
  })
}

// 删除申请模板
export function delTemplate(data) {
  return request({
    url: '/apply/template/delete',
    method: 'post',
    data: data
  })
}
