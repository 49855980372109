<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="申请类型" prop="type" >
        <a-radio-group v-model="form.type">
          <a-radio :value="1">美国签证</a-radio>
          <a-radio :value="2">申根签证</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="申根区域" prop="schengenArea" >
        <a-radio-group v-model="form.schengenArea">
          <a-radio :value="1">国内</a-radio>
          <a-radio :value="2">英国</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否已婚" prop="isMarried" v-if="form.schengenArea == 1">
        <a-radio-group v-model="form.isMarried">
          <a-radio :value="0">否</a-radio>
          <a-radio :value="1">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="当前状态" prop="currentStatus" >
        <a-radio-group v-model="form.currentStatus">
          <a-radio :value="1">我已工作</a-radio>
          <a-radio :value="2">我是学生</a-radio>
          <a-radio :value="3">无业/自由职业</a-radio>
          <a-radio :value="4">个体户</a-radio>
          <a-radio :value="5">企业主</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="资料" prop="profiles" >
        <div style="display: flex;flex-direction: column">
          <label>复印资料</label>
          <a-checkbox-group v-model="form.copyProfiles">
            <a-checkbox v-for="item in copyProfiles" :value="item.id">{{item.title}}</a-checkbox>
          </a-checkbox-group>
        </div>
        <div  style="display: flex;flex-direction: column;margin-top: 10px">
        <label>原件资料</label>
        <a-checkbox-group v-model="form.originalProfiles">
          <a-checkbox v-for="item in originalProfiles" :value="item.id">{{item.title}}</a-checkbox>
        </a-checkbox-group>
        </div>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>

          <a-button type="primary" :loading="submitLoading" @click="resetCheck">
            重置
          </a-button>

          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTemplate, addTemplate, updateTemplate } from '@/api/apply/template'
import {listProfile} from "@/api/visa/profile";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      copyProfiles:[],
      originalProfiles:[],
      // 表单参数
      form: {
        id: null,
        type: null,
        schengenArea:null,
        isMarried:null,
        currentStatus:null,
        profileList: null,
        copyProfiles:[],
        originalProfiles:[],
        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '申请类型不能为空', trigger: 'change' }
        ],
        schengenArea: [
          { required: true, message: '申根区域不能为空', trigger: 'change' }
        ],
        isMarried: [
          { required: true, message: '是否已婚不能为空', trigger: 'change' }
        ],
        currentStatus: [
          { required: true, message: '当前状态不能为空', trigger: 'change' }
        ],
        // profiles: [
        //   { required: true, message: '资料不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
    this.initProfiles()
  },
  activated() {
    this.initProfiles()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    initProfiles(){
      listProfile({profileType:1}).then(res=>{
        this.copyProfiles = res.data
      })
      listProfile({profileType:2}).then(res=>{
        this.originalProfiles = res.data
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        schengenArea:null,
        isMarried:null,
        currentStatus:null,
        profileList: null,
        copyProfiles: [],
        originalProfiles: [],
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTemplate({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    resetCheck(){
      this.form.originalProfiles = []
      this.form.copyProfiles = []
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if(this.form.type == 1){
            if(this.form.originalProfiles.length + this.form.copyProfiles.length < 6){
              this.$message.error("美签不能少于6个材料")
              return
            }
          }else if(this.form.type == 2){
            if(this.form.originalProfiles.length + this.form.copyProfiles.length < 14){
              this.$message.error("申根不能少于14个材料")
              return
            }
          }

          this.submitLoading = true
          this.form.profileList = ''
          let that = this
          if(this.form.originalProfiles){
            this.form.originalProfiles.forEach(item=>{
              if(that.form.profileList){
                that.form.profileList+=','+item
              }else{
                that.form.profileList=item
              }
            })
          }
          if(this.form.copyProfiles){
            this.form.copyProfiles.forEach(item=>{
              if(that.form.profileList){
                that.form.profileList+=','+item
              }else{
                that.form.profileList=item
              }
            })
          }
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTemplate(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '修改成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTemplate(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '新增成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }

            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
