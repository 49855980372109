import request from '@/utils/request'


// 查询签证资料列表
export function listProfile(query) {
  return request({
    url: '/visa/profile/list',
    method: 'get',
    params: query
  })
}

// 查询签证资料分页
export function pageProfile(query) {
  return request({
    url: '/visa/profile/page',
    method: 'get',
    params: query
  })
}

// 查询签证资料详细
export function getProfile(data) {
  return request({
    url: '/visa/profile/detail',
    method: 'get',
    params: data
  })
}

// 新增签证资料
export function addProfile(data) {
  return request({
    url: '/visa/profile/add',
    method: 'post',
    data: data
  })
}

// 修改签证资料
export function updateProfile(data) {
  return request({
    url: '/visa/profile/edit',
    method: 'post',
    data: data
  })
}

// 删除签证资料
export function delProfile(data) {
  return request({
    url: '/visa/profile/delete',
    method: 'post',
    data: data
  })
}
